import { defineStore } from 'pinia';
import appAxios from '../util/app-axios';

function isEmpty(object) {
  if (typeof object === 'string') {
    return (!object || object.length === 0);
  }
  return Object.keys(object).length === 0;
}

export const useGatewayStore = defineStore('vtGateway', {
  state: () => ({
    clientState: '',
    config: {
      authorizeUri: '',
      clientId: '',
      redirectUri: '',
      appVersion: '',
      onboardUri: '',
      docsUri: '',
    },
    // Flag indicating whether a link should be shown to the user to allow creation of a guest account.
    enableCreateGuest: false,
    // The value of the return_url that will be added to the Onboard create account link. This will be the full
    // request URL that the client sent to the /authorize endpoint.
    createGuestReturnTo: '',
    // Flag indicating whether the current request is the result of an Onboard redirect after a user has created an
    // account.
    isOnboardSuccessRedirect: false,
    clientProxyState: {
      id: '',
      clientAssuranceLevels: [],
      scopes: [],
    },
    errorResponse: {
      error: '',
      statusCode: 0,
      errorDescription: '',
      errorUri: '',
      errorLogId: '',
    },

  }),
  actions: {
    setClientState(clientState) {
      this.clientState = clientState;
    },
    setEnableCreateGuest(enabledCreateGuest) {
      this.enableCreateGuest = enabledCreateGuest;
    },
    setCreateGuestReturnTo(returnTo) {
      this.createGuestReturnTo = returnTo;
    },
    setIsOnboardSuccessRedirect(isSuccessRedirect) {
      this.isOnboardSuccessRedirect = isSuccessRedirect;
    },
    setClientProxyState(proxyState) {
      this.clientProxyState.id = proxyState.id;
      this.clientProxyState.clientAssuranceLevels = proxyState.oauthParams.client.assuranceLevels;
      this.clientProxyState.scopes = proxyState.oauthParams.scopes;
    },
    setErrorResponse(errorResponse) {
      this.errorResponse = errorResponse;
    },
    async fetchConfigData() {
      const response = await appAxios.get('/api/config');
      this.config = response.data;
      return response; // This allows for chaining if needed
    },
    async fetchClientProxyState(clientState) {
      const response = await appAxios.get(`/api/states/${clientState}`);
      this.setClientProxyState(response.data);
      return response; // This allows for chaining if needed
    },
  },
  getters: {
    baseAuthUri: (state) => {
      const scope = 'openid email';
      return `${state.config.authorizeUri}?response_type=code`
        + `&client_id=${state.config.clientId}`
        + `&redirect_uri=${state.config.redirectUri}`
        + `&state=${state.clientProxyState.id}`
        + `&scope=${scope}`;
    },
    highAssurance: (state) => state.clientProxyState.clientAssuranceLevels
      .some(level => level.toLowerCase() === 'high'),
    mediumAssurance: (state) => state.clientProxyState.clientAssuranceLevels
      .some(level => level.toLowerCase() === 'medium'),
    lowAssurance: (state) => state.clientProxyState.clientAssuranceLevels
      .some(level => level.toLowerCase() === 'low'),
    onboardReturnToUrl: (state) => {
      const onboardGwAccountCreateUri = `${state.config.onboardUri}/gatewayCreate`;
      if (isEmpty(state.createGuestReturnTo)) {
        return onboardGwAccountCreateUri;
      }
      const returnUrl = encodeURIComponent(`${state.createGuestReturnTo}&onboard_success_create=true`);
      return `${onboardGwAccountCreateUri}?return_url=${returnUrl}`;
    },
  },
});
