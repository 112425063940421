<template>
  <page-loading-wrapper id="discovery-loader" :is-loading="fetchingStateOptions">
    <div id="discovery-page" class="row g-0">
      <div class="col-sm-10 col-lg-6 offset-sm-1 offset-lg-0 align-self-center">
        <div v-if="clientProxyState">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div id="login-card-title" class="col-sm-10 offset-sm-1 mb-4">
                  <h1 class="fst-italic fw-light text-uppercase ">
                    Login
                  </h1>
                </div>
                <div class="col-12 auth-btn">
                  <auth-button
                    v-if="showVtLoginOption"
                    provider="vtAuth"
                    button-text="VT"
                    :to-link="identityProviderLink('Login')"
                  />
                </div>
                <template v-if="showLowAssuranceOption">
                  <div class="col-12 auth-btn">
                    <auth-button
                      provider="googleAuth"
                      button-text="Google"
                      :to-link="identityProviderLink('Google')"
                    />
                  </div>
                  <div class="col-12 auth-btn">
                    <auth-button
                      provider="facebookAuth"
                      button-text="Facebook"
                      :to-link="identityProviderLink('Facebook')"
                    />
                  </div>
                </template>
              </div>

              <div v-if="!clientProxyState.clientAssuranceLevels.length" class="row mt-5">
                <div class="col-12">
                  <h3>No services are provided at this location.</h3>
                </div>
              </div>
              <!-- Show option to create a VT account if the 'enable_create_guest' param was provided by the client AND
                the client is configured for HIGH or MEDIUM assurance accounts. -->
              <div v-if="showCreateGuestAccountLink && showVtLoginOption" class="row mt-5">
                <div class="col-12">
                  Don’t have a Virginia Tech account?
                  <a id="lnk_vtNew" :href="onboardLink" class="vt-link">
                    Click here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-none d-lg-block">
        <jumbotron-image />
      </div>
    </div>
  </page-loading-wrapper>
</template>

<script>
  import { useGatewayStore } from "@/store/store.js";
  import { storeToRefs } from 'pinia';
  import JumbotronImage from '../components/JumbotronImage.vue';
  import PageLoadingWrapper from '../components/PageLoadingWrapper.vue';
  import AuthButton from '../components/AuthButton.vue';

  export default {
    name: 'AuthDiscovery',
    components: {
      PageLoadingWrapper,
      AuthButton,
      JumbotronImage,
    },
    setup() {
      const gatewayStore = useGatewayStore();
      // Destructure to ensure reactivity
      const { clientState, clientProxyState, enableCreateGuest } = storeToRefs(gatewayStore);
      return {
        gatewayStore, clientState, clientProxyState, enableCreateGuest,
      };
    },
    data() {
      return {
        fetchingStateOptions: false,
      };
    },
    computed: {
      showVtLoginOption() {
        return this.gatewayStore.highAssurance || this.gatewayStore.mediumAssurance;
      },
      showLowAssuranceOption() {
        return this.gatewayStore.lowAssurance;
      },
      showCreateGuestAccountLink() {
        return this.enableCreateGuest;
      },
      onboardLink() {
        return this.gatewayStore.onboardReturnToUrl;
      },
      /**
       * Determine if discovery page can be skipped. True if:
       *  1. User is returning from Onboard after creating an account AND their account is MA or HA.
       *  2. Create guest account option is NOT true and VT Login option is the only IdP available.
       */
      skipDiscoveryForLogin() {
        const isOnboardSuccess = this.gatewayStore.isOnboardSuccessRedirect === 'true';
        return (isOnboardSuccess && this.showVtLoginOption)
          || (!this.showCreateGuestAccountLink && this.showVtLoginOption && !this.showLowAssuranceOption);
      },
    },
    mounted() {
      this.fetchingStateOptions = true;
      this.gatewayStore.fetchClientProxyState(this.clientState)
        .finally(() => {
          // Immediately send user to the login page if returning from a successful account creation via onboard.
          if (this.skipDiscoveryForLogin) {
            window.location = this.identityProviderLink('Login');
          } else {
            this.fetchingStateOptions = false;
          }
        });
    },
    methods: {
      identityProviderLink(identityProvider) {
        return `${this.gatewayStore.baseAuthUri}&identity_provider=${identityProvider}`;
      },
    },
  };
</script>

<style scoped>
  .section-break {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .auth-btn + .auth-btn {
    margin-top: 1.4rem;
  }
  /*noinspection CssUnusedSymbol*/
  .card {
    text-align: center;
    border: none;
    background: var(--main-bg-color);
  }
  #login-card-title {
    text-align: left;
  }
  .center-border {
    width:100%;
    text-align:center;
    border-bottom: 1px solid #454545;
    line-height:0.1em; margin:10px 0 20px;
    box-shadow: 2px 2px 5px grey;
  }
  .center-border span {
    padding:0 10px;
    background: var(--main-bg-color);
  }
</style>
