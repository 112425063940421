<template>
  <header id="app-header" class="header">
    <div class="d-none d-sm-flex vt-eyebrow" />
    <nav class="navbar">
      <div class="d-flex">
        <router-link to="/" class="navbar-brand vt-logo-link">
          <img id="vt-logo"
               src="https://www.assets.cms.vt.edu/images/HorizontalStacked/HorizontalStacked_RGB.svg"
               class="d-inline-block logo"
               alt="Virginia Tech VT"
          >
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>

  export default {
    name: 'app-header',
  };
</script>

<style scoped>
  .header {
    padding: 0;
    background-color: white;
    border-bottom: 1px solid #d3d3d34f;
    height: 90px;
    z-index: 2;
  }

  @media (min-width: 768px) {
    .header {
      height: 96px;
    }
  }

  .navbar {
    --bs-navbar-padding-x: 1rem;
  }

  .vt-eyebrow {
    background-color: var(--dark-bg-color);
    height: 6px;
  }

  .logo {
    width: 210px;
    float: left;
  }

  .vt-logo-link:focus {
    outline-color: #75787b;
  }
</style>
