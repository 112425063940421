<template>
  <div class="main-body">
    <app-header id="app-header" />
    <main id="main-content" class="container-fluid">
      <router-view />
    </main>
    <app-footer id="app-footer" />
  </div>
</template>

<script>
  import AppHeader from "./components/AppHeader.vue";
  import AppFooter from './components/AppFooter.vue';

  export default {
    name: 'App',
    components: {
      AppHeader,
      AppFooter,
    },
  };
</script>

<style>
  /*noinspection CssUnusedSymbol*/
  #app, .main-body {
    min-height: 100vh;
  }
  .main-body {
    display: flex;
    flex-direction: column;
  }

  #main-content {
    padding: 0;
  }
</style>
