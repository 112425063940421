<template>
  <div class="row">
    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
      <div class="card my-5" title="Unexpected Gateway Error">
        <div class="card-body">
          <h4 class="card-title">
            Unexpected Gateway Error
          </h4>
          <hr>
          <div class="row mb-2">
            <div class="col-sm-3 col-xl-2 fw-bold">
              Error
            </div>
            <div class="col-sm-9 col-xl-10">
              {{ errorResponse.statusCode }}:{{ errorResponse.error }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3 col-xl-2 fw-bold">
              Description
            </div>
            <div class="col-sm-9 col-xl-10">
              {{ errorMessage }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3 col-xl-2 fw-bold">
              Error URI
            </div>
            <div class="col-sm-9 col-xl-10">
              {{ errorResponse.errorUri }}
            </div>
          </div>
        </div>
        <div class="card-footer">
          <small class="text-muted">ref: {{ errorResponse.errorLogId }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useGatewayStore } from '@/store/store';
  import { storeToRefs } from 'pinia';

  export default {
    name: 'AppError',
    setup() {
      const gatewayStore = useGatewayStore();
      // Destructure to ensure reactivity
      const { errorResponse } = storeToRefs(gatewayStore);
      return {
        errorResponse
      }
    },
    data() {
      return {
        defaultMessage: 'Unspecified error occurred. Please try again later.',
      };
    },
    computed: {
      errorMessage() {
        if (this.errorResponse.errorDescription) {
          return this.errorResponse.errorDescription;
        }
        return this.defaultMessage;
      },
    },
  };
</script>
