import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import { useGatewayStore } from '@/store/store';
import { createAppRouter } from '@/router/router';
import 'typeface-roboto/index.css';
import '@/assets/css/fonts-cms.css';
import '@/assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

/* Add the desired Font-Awesome fonts to the library for later use */
library.add(faSpinner, faAngleRight);
const app = createApp(App);
app.use(createPinia());
// Initialize the store and fetch config data
const gatewayStore = useGatewayStore();
gatewayStore.fetchConfigData().finally(() => {
  app.config.productionTip = false;
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.use(createAppRouter(gatewayStore))
  app.mount('#app');
});
