<template>
  <div>
    <template v-if="isLoading">
      <div class="row" :class="formatClasses">
        <div class="col flex-container">
          <font-awesome-icon
            id="loading-spinner"
            icon="spinner" spin size="3x" style="color: #861f41"
          />
          <span v-if="loadingMessage" id="loading-message">{{ loadingMessage }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
  export default {
    name: 'PageLoadingWrapper',
    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
      loadingMessage: {
        type: String,
        required: false,
        default: 'Loading...',
      },
      /* String of css classes to add for styling */
      formatClasses: {
        type: String,
        required: false,
        default: '',
      },
    },
  };
</script>

<style scoped>
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #loading-message {
    margin-left: 2rem;
  }
</style>
