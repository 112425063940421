<template>
  <a :id="btnId" class="auth-button" :href="toLink">
    <div class="grid-wrapper">
      <span class="icon" />
      <span class="text-start">Login with <b>{{ buttonText }}</b></span>
    </div>
  </a>
</template>

<script>
  export default {
    name: 'AuthButton',
    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
      provider: {
        type: String,
        required: true,
        validator(value) {
          return ['vtAuth', 'googleAuth', 'facebookAuth'].indexOf(value) !== -1;
        },
      },
      buttonText: {
        type: String,
        required: true,
      },
      toLink: {
        type: String,
        required: true,
      },
    },
    computed: {
      btnId() {
        if (!this.id) {
          return `btn_${this.provider}`;
        }
        return this.id;
      },
    },
  };
</script>

<style scoped>
  /*See the following resources for branding specific to Google and Facebook
    Google: https://developers.google.com/identity/branding-guidelines
      https://developers.google.com/identity/sign-in/web/build-button
    Facebook: https://developers.facebook.com/docs/facebook-login/userexperience/
      https://developers.facebook.com/docs/facebook-login/userexperience/#buttondesign
  */

  /* ********** Common CSS Attributes ********** */
  .auth-button {
    padding: 5px;
    width: 230px;
    height: auto;
    white-space: nowrap;
    display: inline-block;
    color: var(--white);
    border: 1px solid #555;
    font-family: gineso-condensed, sans-serif;
    font-size: 1.2rem;
  }
  .grid-wrapper {
    display: grid;
    grid-column-gap: 18px;
    grid-template-columns: min-content 1fr;
    align-items: center;
  }
  .icon {
    width: 42px;
    height: 42px;
    background-position-x: center;
    background-position-y: 50%;
    background-size: 32px;
    background-origin: initial;
    background-clip: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  /* ********** VT CSS Attributes ************** */
  /*noinspection CssUnusedSymbol*/
  #btn_vtAuth {
    background-color: var(--chicago-maroon-color);
    border-color: var(--chicago-maroon-color);
  }
  /*noinspection CssUnusedSymbol*/
  #btn_vtAuth:hover {
    background-color: var(--chicago-maroon-darker);
    border-color: var(--chicago-maroon-darker);
  }
  #btn_vtAuth .icon {
    background-image: url('../assets/vt-logo-type-white-transparent.svg');
  }

  /* ********** Google CSS Attributes ********** */
  /*noinspection CssUnusedSymbol*/
  #btn_googleAuth {
    background-color: var(--google-color);
    border-color: var(--google-color);
  }
  /*noinspection CssUnusedSymbol*/
  #btn_googleAuth:hover {
    background-color: var(--google-color-darker);
    border-color: var(--google-color-darker);
  }
  #btn_googleAuth .icon {
    background-color: var(--white);
    background-image: url('../assets/google-less-mass-02.svg');
  }

  /* ********** Facebook CSS Attributes ******** */
  /*noinspection CssUnusedSymbol*/
  #btn_facebookAuth {
    background-color: var(--facebook-color);
    border-color: var(--facebook-color);
  }
  /*noinspection CssUnusedSymbol*/
  #btn_facebookAuth:hover {
    background-color: var(--facebook-color-darker);
    border-color: var(--facebook-color-darker);
  }
  #btn_facebookAuth .icon {
    background-image: url('../assets/f_logo_RGB-White_58.png');
  }
</style>
