<template>
  <div v-if="imgSourceLoaded" class="vt-image">
    <figure>
      <picture>
        <!-- On screen sizes less than max-width, download transparent gif instead of fetching a heavy image file -->
        <source media="(max-width: 991px)"
                sizes="1px"
                srcset="../assets/blank.gif 1w"
        >
        <img v-for="image in currentSeasonalImg" id="vt-image"
             :key="image.season"
             sizes="(max-width: 4500px) 45vw, 1800px"
             :srcset="srcSet"
             :src="src"
             :alt="image.alt"
             class="img-fluid"
        >
      </picture>
    </figure>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  /** Images came from VT's
   * https://www.photolibrary.unirel.vt.edu/.
   * Compressed with guetzli
   * (https://github.com/google/guetzli).
   * Converted into responsive sizes via Cloudinary's
   * https://www.responsivebreakpoints.com/ */

  export default {
    name: 'JumbotronImage',
    data() {
      return {
        imageWidths: [], // array that collects the current season's image widths as strings
        srcSet: '',
        src: '',
        currentSeason: '',
        imageInfoArr: [
          {
            season: 'spring',
            alt: 'A tree in bloom in front of Burruss Hall',
          },
          {
            season: 'summer',
            alt: 'Hahn Horticulture Garden',
          },
          {
            season: 'autumn',
            alt: 'Students cross the drillfield on an autumn day',
          },
          {
            season: 'winter',
            alt: 'Owens Hall in snow',
          },
        ],
      };
    },
    computed: {
      currentSeasonalImg() {
        const { currentSeason } = this;
        return this.imageInfoArr.filter(image => image.season === currentSeason);
      },
      /**
       * @returns {boolean} Returns true if both src and srcSet are truthy.
       * This computed property is used to determine when/whether to render this template.
       */
      imgSourceLoaded() {
        return !!this.src && !!this.srcSet;
      }
    },
    async mounted() {
      const currentSeason = this.getSeason(); // get the current season
      this.setCurrentSeason(currentSeason);
      const images = await this.processAllSeasonalImages();
      const currentSeasonImages = this.getCurrentSeasonImages(images, currentSeason);
      // filter out images that aren't for the current season
      this.getSrcSet(currentSeasonImages);
      this.getImgSrc(currentSeasonImages);
    },
    methods: {
      getSeason() {
        const date = new Date();
        const month = date.getMonth() + 1; /** JS months start at 0 (January)
         so increment by 1 to bring month variable
         in line with popular convention */
        let season;
        if (month >= 9 && month <= 11) {
          season = 'autumn';
        } else if (month === 12 || month < 3) {
          season = 'winter';
        } else if (month >= 3 && month <= 5) {
          season = 'spring';
        } else {
          season = 'summer';
        }
        return season;
      },
      setCurrentSeason(currentSeason) {
        this.currentSeason = currentSeason;
      },
      /** Use Vite's glob to grab all
       * seasonal images. Returns an array of the images */
      async processAllSeasonalImages() {
        const imageImports = import.meta.glob('../assets/images/jumbotron/**/*.jpg');
        return await Promise.all(
          Object.keys(imageImports).map(async (path) => {
            const module = await imageImports[path]();
            return module.default;
          })
        );
      },
      /** Filter the images based on the current season.
       * Returns an array of the current season's images.
       * Important: the season is included in each
       * of the image's name */
      getCurrentSeasonImages(imgArr, season) {
        return imgArr.filter(image => image.includes(season));
      },
      /** Returns a string for the srcset attribute (this.srcSet).
       * Also populates the imageWidths array.
       * Important: the width is included in each of the image's name */
      getSrcSet(arr) {
        const srcSetArr = arr.map((image) => {
          // Vite converts the `,` in the image path to `_`, so the regex is looking for `_w_` instead of `,w_`
          const regex = /_w_(\d+)-/;
          const match = image.match(regex);
          if (!match) {
            // eslint-disable-next-line no-console
            console.error("Width descriptor not found in filename:", image);
            return '';
          }
          // Capture the width, which is the first group in the regex
          const imgWidth = match[1];
          if (!imgWidth || isNaN(parseInt(imgWidth, 10))) {
            // eslint-disable-next-line no-console
            console.error("Invalid width extracted from filename:", image);
            return '';
          }
          this.imageWidths.push(imgWidth);
          return `${image} ${imgWidth}w`;
        });
        // Filter out any empty strings to ensure the srcset string remains valid
        this.srcSet = srcSetArr.filter(entry => entry !== '').join(',');
      },
      /** The default image source is
       * the largest image in the current season's array.
       * Returns a string for the src attribute (this.src) */
      getImgSrc(arr) {
        const imgWidths = this.imageWidths;
        const imgWidthsAsNums = imgWidths.map(Number);
        const maxWidth = Math.max(...imgWidthsAsNums);
        this.src = arr.filter(image => image.includes(maxWidth));
      },
    },
  };
</script>

<style scoped>

  .vt-image img {
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,.4);
  }
</style>
