<template>
  <footer class="footer text-center py-2">
    <p class="mb-3">
      <a id="link-acceptable-use"
         href="https://vt.edu/about/acceptable-use.html"
         target="_blank" rel="noopener noreferrer"
      >
        Acceptable Use
      </a> |
      <a id="link-privacy"
         href="https://vt.edu/privacy.html"
         target="_blank"
         rel="noopener noreferrer"
      >
        Privacy Statement
      </a> |
      <a v-if="config.docsUri" id="docs-link" :href="config.docsUri" target="_blank" rel="noopener noreferrer">
        Developer Docs
      </a>
    </p>
    <div class="small">
      <span v-if="config.appVersion" id="app-version">{{ config.appVersion }}</span>
      <span id="app-copyright"> &copy; {{ currentYear }} Virginia Polytechnic Institute and State University</span>
    </div>
  </footer>
</template>

<script>
  import { useGatewayStore } from '@/store/store';
  import { storeToRefs } from 'pinia';

  export default {
    name: 'AppFooter',
    setup() {
      const gatewayStore = useGatewayStore();
      // Destructure to ensure reactivity
      const { config } = storeToRefs(gatewayStore);
      return {
        config
      };
    },
    data() {
      return {
        currentYear: new Date().getFullYear(),
      };
    },
  };
</script>

<style scoped>
  .footer {
    background-color: var(--dark-bg-color);
    color: white;
    margin-top: auto;
  }

  .footer a {
    color: white;
  }

  .footer a:hover {
    color: white;
    text-decoration: underline !important;
  }

  .footer a:focus {
    outline-color: var(--white);
  }

</style>
