import { createRouter, createWebHistory } from 'vue-router';
import AuthDiscovery from '../views/AuthDiscovery.vue';
import AppError from '../views/AppError.vue';

export function createAppRouter(store) {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'AuthDiscovery',
        component: AuthDiscovery,
      },
      {
        path: '/error',
        name: 'AppError',
        component: AppError,
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    if (to.path === '/') {
      if (to.query.state) {
        store.setClientState(to.query.state);
        if (to.query.enable_create_guest && to.query.create_guest_return_to) {
          store.setEnableCreateGuest(to.query.enable_create_guest);
          store.setCreateGuestReturnTo(to.query.create_guest_return_to);
        }
        if (to.query.onboard_success_create) {
          store.setIsOnboardSuccessRedirect(to.query.onboard_success_create);
        }
        // Call next() once after handling state parameters
        next();
        // Prevent further execution of the guard
        return;
      }
      // Check for error parameters only if state is not present
      if (to.query.error) {
        store.setErrorResponse({
          error: to.query.error,
          statusCode: to.query.status_code,
          errorDescription: to.query.error_description,
          errorUri: to.query.error_uri,
          errorLogId: to.query.error_log_id,
        });
        // Redirect to the error route
        next('/error');
        // Prevent the second call to next()
        return;
      }
    }
    // Call next() for other routes or if neither condition is met
    next();
  });

  return router;
}
