import axios from 'axios';

const instance = axios.create({
  withCredentials: true,
});

instance.interceptors.request.use((c) => {
  const config = c;
  if (config.method.toLowerCase() === 'post') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  config.baseURL = '/';
  return config;
});

// Add a response interceptor, which sets csrf header and also handles errors.
instance.interceptors.response.use(response => response, (error) => {
  if (error.response) {
    // eslint-disable-next-line
    console.log(`Error: ${error}`);
  }
  return Promise.reject(error);
});

export default instance;
